<template>
    <div class="product-faq">
        <strong>{{ title }}</strong>
        <div v-for="(faq, index) in productFaq" class="faq" :class="{ active: isExpanded(index) }" @click="toggleExpansion(index)">
            <div class="faq-heading">
                <div class="faq-question" v-text="faq.question"/>
                <i class="fas fa-plus"></i>
            </div>
            <div v-show="isExpanded(index)" class="faq-body">
                <div class="faq-answer" v-html="faq.answer" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productFaq: window.productFaqData.productFaq,
                title: window.productFaqData.faqTitle,
                expandedGroup: []
            }
        },
        methods: {
            isExpanded(index) {
                return this.expandedGroup.indexOf(index) !== -1;
            },
            toggleExpansion(index) {
                if (this.isExpanded(index)) {
                    this.expandedGroup.splice(this.expandedGroup.indexOf(index), 1);

                } else {
                    this.expandedGroup.push(index);
                }
            }
        }
    }
</script>
