requirejs([
        'jquery'
    ],
    function ($) {
        $('select[name=country_id]').ready( () => {
            let options;
            let loop = setInterval(() => {
                options = $('select[name=country_id] option');

                if (options.length > 0) {
                    options.each(function () {
                        if ($(this).text() === ' ') {
                            $(this).remove();
                        }
                    })
                    clearInterval(loop);
                }
            }, 10);
        });
    });
