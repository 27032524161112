<template>
    <div class="repeat-order">
        <div class="title">
            <span v-html="title"></span>
        </div>
        <div class="content">
            <div class="description">
                <div v-html="description"></div>
            </div>
            <form @submit="formSubmit">
                <input
                    v-model="email"
                    type="email"
                    :placeholder="placeholder"
                    required>
                <button
                    type="submit"
                    v-text="buttonLabel"
                    :disabled="isSubmit"/>
            </form>
            <div class="message" v-if="message"><p v-html="message"></p></div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'

    export default {
        data() {
            return {
                title: window.repeatOrderData.repeatOrderWidgetTitle,
                description: window.repeatOrderData.repeatOrderWidgetDescription,
                submitText: window.repeatOrderData.submitText,
                placeholder: window.repeatOrderData.placeholder,
                email: '',
                isSubmit: false,
                message: ''
            }
        },
        methods: {
            formSubmit(element) {
                this.isSubmit = true;
                let self = this
                element.preventDefault()
                $.ajax({
                    url: '/repeatorder/repeatorder/index',
                    type: 'POST',
                    dataType: 'application/x-www-form-urlencoded',
                    data: {
                        email: this.email,
                        form_key: document.getElementsByName('form_key')[0].value
                    },
                    complete: function(response) {
                        self.email = ''
                        self.isSubmit = false
                        self.message = JSON.parse(response.responseText).message
                    }
                })
            }
        },
        computed: {
            buttonLabel() {
                return window.repeatOrderData.submitText
            }
        },
    }
</script>
