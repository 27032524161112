requirejs([
    'jquery',
    'swiper',
    'mage/translate'
],

function($, Swiper, $t) {
    $(document).ready(() => {
        /**
         * Add line under active contact main menu item
         */
        if ($('body').hasClass('contact-index-index')) {
            $('.contact .main-menu__link').addClass('current');
        }

        /**
         * Add line under active product main menu item
         */
        if ($('body').hasClass('catalog-product-view')) {
            let pathname = '';
            $.each($('body').attr('class').split(/\s+/), function(index, item) {
                pathname = window.location.pathname;
                if (item.includes(pathname.substring(1))) {
                    $('a[href="' + pathname + '"].main-menu__link').addClass('current');
                }
            });
        }
    });

    if ( $('.header .header-top-left').length ) {
        const breakpoint = window.matchMedia( '(max-width:1200px)' );
        var headerUspsSwiper;
        const breakpointChecker = function() {
            if ( breakpoint.matches === false) {
                if ( headerUspsSwiper !== undefined ) {
                    headerUspsSwiper.destroy( true, true );
                    $('.header .header-top-left ul').unwrap('.swiper-container-usps');
                    $('.header .header-top-left ul').removeClass('swiper-wrapper');
                    $('.header .header-top-left li').removeClass('swiper-slide');
                    $('.swiper-button-prev, .swiper-button-next, header .header-top-left li.list-review-wrapper').remove();
                }
                return;
            } else if ( breakpoint.matches === true ) {
                return enableSwiper();
            }
        };
        const enableSwiper = function() {
            let reviewListWrapper = '<li class="list-review-wrapper"></li>';
            $(reviewListWrapper).prependTo($('.header .header-top-left ul'));
            $('.header .header-top-left .header-review').appendTo($('.header .header-top-left ul li.list-review-wrapper'));
            $('.header .header-top-left ul').wrap('<div class="swiper-container-usps"></div>')
            $('.header .header-top-left ul').addClass('swiper-wrapper');
            $('.header .header-top-left li').addClass('swiper-slide');

            var slidesPerColumn = 1,
            slidesPerColumnResponsive = 1;

            headerUspsSwiper = new Swiper('.swiper-container-usps', {
                loop: true,
                allowTouchMove: false,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                  },
                slidesPerView: 1,
                spaceBetween: 0,
                breakpoints: {
                    // when window width is <= 1200px
                    1200: {
                        slidesPerColumn: 1
                    }
                },
            });
        };
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
    }
});
