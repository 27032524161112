<template>
    <div v-if="showPopup" class="vue-popup location-check-popup">
        <div class="modal-backdrop"></div>
        <div class="modal" v-on-clickaway="cancel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title" v-html="t.title"></div>
                        <div class="close">
                            <i @click="cancel()" class="fas fa-times"></i>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="content" v-html="t.description"></div>
                    </div>
                    <div class="modal-footer">
                        <button @click="cancel()" title="Blijf hier" class="action secondary">
                            <span v-text="t.no" />
                        </button>
                        <button @click="redirect()" title="Verwijs mij door" class="action primary">
                            <span v-text="t.yes" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueCookies from 'vue-cookies'
    import { mixin as clickaway } from 'vue-clickaway';

    export default {
        mixins: [ clickaway ],
        data() {
            return {
                showPopup: false,
                currentLocation: '',
                locationCheckData: window.locationCheckData,
                t: locationCheckData.translatableStrings
            }
        },
        methods: {
            cancel() {
                this.showPopup = false
                $cookies.set('showLocationCheckPopup', 0, '1d')
            },
            redirect() {
                this.showPopup = false
                $cookies.set('showLocationCheckPopup', 0, '1d')
                window.location.replace(locationCheckData.redirectStoreUrl);
            },
            setCurrentLocation() {
                return axios.get('https://ipinfo.io/json').then(response => {
                    this.currentLocation = response.data.country.toLowerCase()
                })
            },
            clientIsInStoreLocation() {
                return this.locationCheckData.localeCode === this.currentLocation
            },
            clientIsInMappedLocation() {
                return this.locationCheckData.storeLocationMap[this.currentLocation] === this.locationCheckData.localeCode;
            },
            async checkShowPopup() {
                if ($cookies.get('showLocationCheckPopup') == false || !locationCheckData.redirectStoreUrl) {
                    return false;
                }

                await this.setCurrentLocation();

                this.showPopup = !this.clientIsInStoreLocation() && this.clientIsInMappedLocation();
            }
        },
        mounted: async function() {
            await this.checkShowPopup()
        }
    }
</script>
