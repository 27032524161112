import Vue from 'vue'
import QtySelect from './QtySelect.vue'
import QtySelectCart from './QtySelectCart.vue'
import VuePluralize from 'vue-pluralize'
Vue.use(VuePluralize);

if (document.getElementById('volume-discount-qty-select')) {
    new Vue({
        el: '#volume-discount-qty-select',
        render: h => h(QtySelect)
    })
}

let elements = document.getElementsByClassName('volume-discount-qty-select-cart');
if (elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
        let elementId = elements[i].id;
        (function (el) {
            new Vue({
                el: '#' + elementId,
                render: h => h(QtySelectCart),
                data: () => Object.assign({}, el.dataset)
            })
        })(document.getElementById(elementId));
    }
}
