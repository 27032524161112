<template>
    <div id="volume-discount-qty-select-cart" class="volume-discount-qty-select-cart">
        <div class="volume-discount-qty-select-cart-button" @click="openPopup">
            <div class="qty-products">
                {{ qty }}
                <span>x</span>
            </div>
            <a class="volume-discount-edit-button" :title="qty + t.edit">
                <span>{{ t.edit }}</span>
            </a>
        </div>
            <div v-if="showPopup" class="vue-popup volume-discount-qty-select-popup">
                <div class="modal-backdrop"></div>
                <div class="modal" v-on-clickaway="cancel">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title" v-html="t.increaseDiscount" />
                                <div class="close">
                                    <i @click="cancel()" class="fas fa-times"></i>
                                </div>
                            </div>
                            <div class="modal-body">
                                <div class="content">
                                    <p>{{ t.replenishStock }}</p>
                                    <a :href="productUrl">{{ product.name }}</a>
                                    <div class="box-tocart">
                                        <div class="fieldset">
                                            <div class="field qty">
                                                <div class="control">
                                                    <qty-select-box
                                                        :product="product"
                                                        :tierPrices="tierPrices"
                                                        :defaultQty="defaultQty"
                                                        :productDefaultQty="defaultQty"
                                                        :setsText="setsText"
                                                        :perText="t.per"
                                                        :orderPerText="t.orderPer"
                                                        :qtySteps="qtySteps"
                                                        :localeCode="localeCode"
                                                        :currencyCode="currencyCode"
                                                        :qty="qty"
                                                        :price="parseFloat(price)"
                                                        :t="t"
                                                        :moreLink="moreLink"
                                                    />
                                                </div>
                                                <div class="price-box price-final_price">
                                                    <span class="price-container price-reactive_discount_price">
                                                        <span class="price-wrapper ">
                                                            <span v-html="formatPrice(price)" class="price" />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button @click="cancel()" :title="t.cancel" class="secondary">
                                    <span v-text="t.cancel" />
                                </button>
                                <a @click="updateQuoteQuantity()" :title="t.spare + ' ' + discountPrice" class="primary">
                                    <span v-if="Number(discountPrice.replace(/[^0-9.-]+/g,'')) > 0" v-text="t.spare + ' ' + discountPrice" />
                                    <span v-else v-text="t.apply" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from 'vue-clickaway'
    import QtySelectBox from './components/QtySelectBox.vue'
    import axios from 'axios'

    export default {
        mixins: [ clickaway ],
        components: {
            'qty-select-box': QtySelectBox
        },
        data() {
            return {
                quoteItemId: this.$parent.quoteItemId,
                product: JSON.parse(this.$parent.product),
                tierPrices: JSON.parse(this.$parent.tierprices),
                qty: JSON.parse(this.$parent.qty),
                defaultQty: JSON.parse(this.$parent.defaultQty),
                setsText: this.$parent.setsText,
                qtySteps: JSON.parse(this.$parent.qtySteps),
                localeCode: this.$parent.localeCode,
                currencyCode: this.$parent.currencyCode,
                t: JSON.parse(this.$parent.translate),
                rowTotal: this.$parent.rowTotal,
                showPopup: false,
                price: '',
                discountPercentage: '',
                discountPrice: '',
                baseUrl: window.BASE_URL,
                moreLink: JSON.parse(this.$parent.moreLink)
            }
        },
        methods: {
           formatPrice(price) {
                return new Intl.NumberFormat(
                    this.formattedLocaleCode,
                    {
                        style: 'currency',
                        currency: this.currencyCode
                    }
                ).format(price)
            },
            openPopup() {
                this.showPopup = true
                this.price = this.rowTotal
            },
            cancel() {
                this.showPopup = false
            },
            updateQuoteQuantity() {
                let url = this.baseUrl + `reactivevolumediscount/index/index?isAjax=true&form_key=${this.formKey}`

                axios.post(url, {
                    quoteItemId: this.quoteItemId,
                    qty: this.selectedQty
                }).then(response => {
                    if (response.data.success) {
                        location.reload()
                    }
                })
            }
        },
        computed: {
            formattedLocaleCode() {
                return this.localeCode.replace('_', '-')
            },
            formKey() {
                return document.querySelector('[name=form_key]').value
            },
            productUrl() {
                return this.baseUrl + this.product.request_path
            }
        },
        created() {
            this.selectedQty = this.qty
        }
    }
</script>
