<template>
    <div>
        <p>
            {{ productPrice }} {{ perText }} {{ setsText }}. {{ orderPerText }} {{ qtySteps }} {{ setsText | pluralize(qtySteps) }}
        </p>
        <select
            v-show="false"
            name="qty"
            id="qty"
            :value="selectedOption.qty"
            class="qty customizer__qty validation-passed">
            <option v-for="option in options" :value="option.qty"></option>
        </select>
        <multiselect
            v-model="selectedOption"
            :value="selectedOption"
            track-by="qty"
            name="qty"
            label="discountPercentage"
            open-direction="bottom"
            :options="options"
            :searchable="false"
            :allow-empty="false"
            @select="selectQuantity"
            select-label=""
            selected-label=""
            deselect-label="">
                <template slot="singleLabel" slot-scope="props">
                    <span>{{ props.option.qty }}</span> {{ setsText | pluralize(props.option.qty) }}
                    <span v-if="props.option.discountPercentage && props.option.discountPercentage < 100" class="discount">
                        -{{ props.option.discountPercentage }}%
                    </span>
                </template>
                <template slot="option" slot-scope="props">
                    <span v-if="props.option.qty">
                        <span>{{ props.option.qty }}</span> {{ setsText | pluralize(props.option.qty) }}
                        <span v-if="props.option.discountPercentage && props.option.discountPercentage < 100" class="discount">
                            -{{ props.option.discountPercentage }}%
                        </span>
                    </span>
                    <span v-else>{{ props.option }}</span>
                </template>
        </multiselect>
        <p v-if="notificationMessage" class="discount-bar" v-html="notificationMessage" />
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            tierPrices: {
                type: Array,
                required: true
            },
            defaultQty: {
                type: Number,
                required: true
            },
            productDefaultQty: {
                type: Number,
                required: true
            },
            setsText: {
                type: String,
                required: true
            },
            perText: {
                type: String,
                required: true
            },
            orderPerText: {
                type: String,
                required: true
            },
            qtySteps: {
                type: Number,
                required: true
            },
            localeCode: {
                type: String,
                required: true
            },
            currencyCode: {
                type: String,
                required: true
            },
            qty: {
                type: Number,
                required: false
            },
            price: {
                type: Number,
                required: false
            },
            t: {
                type: Object,
                required: true
            },
            moreLink: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                selectedOption: {
                    qty: this.productDefaultQty,
                    price: this.product.price
                },
                options: [],
                productDefaultPrice: '',
                discountPercentage: '',
                discountPrice: '',
                pluralRules: {
                    'doos': 'dozen',
                    'satz': 'Sätze',
                    'Stück': 'Stück',
                    'zestaw': 'zestawy',
                    'sztuka': 'sztuk',
                    'pudełko': 'pudełka'
                }
            }
        },
        methods: {
            setOptions() {
                let index = 0

                let maxQtySelect = this.product.max_qty_select
                if (!maxQtySelect) {
                    maxQtySelect = 150
                }

                for (let qty = this.qtySteps; qty <= maxQtySelect; qty += this.qtySteps) {
                    this.options.push({'qty': qty})
                    let tierPrice = this.findTierPrice(qty) ? parseFloat(this.findTierPrice(qty).price) : this.product.price
                    let discountPercentage = 100 - tierPrice / this.productDefaultPrice * 100
                    this.options[index]['discountPercentage'] = discountPercentage > 0 ? +discountPercentage.toFixed(1) : false
                    this.options[index]['price'] = tierPrice
                    index++
                }
                this.options.push(this.moreLink.text)
            },
            findTierPrice(qty) {
                let tierPrice = this.tierPrices.find(t => parseFloat(t.price_qty) === parseFloat(qty))
                if (qty > 0 && tierPrice === undefined) {
                    tierPrice = this.findTierPrice(qty - 1)
                }

                return tierPrice !== undefined ? tierPrice : false
            },
            isProductPage() {
                return document.getElementsByClassName('catalog-product-view').length > 0
            },
            isCartPage() {
                return document.getElementsByClassName('checkout-cart-index').length > 0
            },
            selectQuantity(option) {
                if (!option.qty) {
                    window.location.href = this.moreLink.url

                    return
                }

                this.discountPercentage = option.discountPercentage
                this.discountPrice = parseFloat(this.productDefaultPrice) * option.qty - this.findTierPrice(option.qty).price * option.qty

                if (this.isProductPage()) {
                    this.product.price = this.findTierPrice(option.qty) ? this.findTierPrice(option.qty).price : this.productDefaultPrice
                    document.querySelector(".price-final_price .price").innerHTML = this.findTierPrice(option.qty) ? this.formatPrice(this.findTierPrice(option.qty).price * option.qty) : this.formatPrice(this.product.price * option.qty)
                    if (document.querySelector(".old-price .price") && this.product.advice_price) {
                        document.querySelector(".old-price .price").innerHTML = this.findTierPrice(option.qty)
                            ? this.formatPrice((this.product.advice_price - this.product.advice_price * Number(this.findTierPrice(option.qty).percentage_value / 100)) * option.qty)
                            : this.formatPrice(this.product.advice_price * option.qty)
                    }
                }

                if (this.isCartPage()) {
                    this.$parent.price = this.findTierPrice(option.qty) ? this.findTierPrice(option.qty).price * option.qty : this.product.price * option.qty
                    this.$parent.discountPrice = this.formatPrice(this.discountPrice)
                    this.$parent.selectedQty = option.qty
                }
            },
            formatPrice(price) {
                return new Intl.NumberFormat(
                    this.formattedLocaleCode,
                    {
                        style: 'currency',
                        currency: this.currencyCode
                    }
                ).format(price)
            },
            setPluralRules() {
                for (const [key, value] of Object.entries(this.pluralRules)) {
                    this.$pluralize.addSingularRule(key, key)
                    this.$pluralize.addPluralRule(key, value)
                }
            }
        },
        computed: {
            formattedLocaleCode() {
                return this.localeCode.replace('_', '-')
            },
            productPrice() {
                return this.formatPrice(this.product.price)
            },
            notificationMessage() {
                if (this.discountPercentage) {
                    return `${this.t.youHaveA} ${this.discountPercentage}% ${this.t.discountAndSave} ${this.formatPrice(this.discountPrice)}.`
                }

                return `${this.t.orderMore}`
            },
        },
        mounted() {
            this.productDefaultPrice = this.product.price
            let tierPrice = this.findTierPrice(this.selectedOption.qty) ? parseFloat(this.findTierPrice(this.selectedOption.qty).price) : false
            this.discountPrice = tierPrice ? parseFloat(this.productDefaultPrice) * this.selectedOption.qty - tierPrice * this.selectedOption.qty : 0
            this.$parent.discountPrice = this.formatPrice(this.discountPrice)
            if (this.isProductPage()) {
                this.product.price = this.findTierPrice(this.productDefaultQty) ? this.findTierPrice(this.productDefaultQty).price : this.product.price

                document.querySelector(".price-final_price .price").innerHTML =
                    this.findTierPrice(this.selectedOption.qty) ?
                        this.formatPrice(this.product.price * this.selectedOption.qty) :
                        this.formatPrice(this.product.price * this.selectedOption.qty)

                if (document.querySelector(".old-price .price") && this.product.advice_price) {
                    document.querySelector(".old-price .price").innerHTML = this.formatPrice(this.product.advice_price * this.selectedOption.qty)
                }

                this.discountPercentage = this.discountPrice > 0 ? this.discountPrice / ((this.productDefaultPrice * this.selectedOption.qty) / 100) : false
                this.discountPercentage = this.discountPercentage ? +this.discountPercentage.toFixed(1) : false
                this.selectedOption = {
                    qty: this.selectedOption.qty,
                    price: this.product.price,
                    discountPercentage: this.discountPercentage
                }
            }
            if (this.isCartPage()) {
                if (this.qty && this.price) {
                    this.selectedOption.price = this.productDefaultPrice
                    this.discountPercentage = +(this.discountPrice / ((this.productDefaultPrice * this.selectedOption.qty) / 100)).toFixed(1)
                    this.selectedOption = {
                        qty: this.qty,
                        price: this.productDefaultPrice,
                        discountPercentage: this.discountPercentage
                    }
                }
                this.discountPercentage = this.selectedOption.discountPercentage
            }
            this.setPluralRules()
            this.setOptions()
        }
    }
</script>
