import Vue from 'vue'
import QuickFilter from './quick-filter/QuickFilter.vue'
import RepeatOrder from './repeat-order/RepeatOrder.vue'

if (document.getElementById('quick-filter')) {
    new Vue({
        el: '#quick-filter',
        render: h => h(QuickFilter)
    })
}

if (document.getElementById('repeat-order')) {
    new Vue({
        el: '#repeat-order',
        render: h => h(RepeatOrder)
    })
}
