<template>
    <div>
        <qty-select-box
            :product="product"
            :tierPrices="tierPrices"
            :defaultQty="defaultQty"
            :productDefaultQty="defaultQty"
            :setsText="setsText"
            :perText="perText"
            :orderPerText="orderPerText"
            :qtySteps="qtySteps"
            :localeCode="localeCode"
            :currencyCode="currencyCode"
            :t="t"
            :moreLink="moreLink"
        />
    </div>
</template>

<script>
    import QtySelectBox from './components/QtySelectBox.vue'

    export default {
        components: {
            'qty-select-box': QtySelectBox
        },
        data() {
            return {
                product: window.product,
                tierPrices: window.tierPrices,
                defaultQty: window.productDefaultQty,
                setsText: window.setsText,
                perText: window.perText,
                orderPerText: window.orderPerText,
                qtySteps: window.productQtySteps,
                localeCode: window.localeCode,
                currencyCode: window.currencyCode,
                t: window.discountTranslations,
                moreLink: JSON.parse(window.moreLink)
            }
        }
    }
</script>
