requirejs([
        'jquery',
    ],
    function ($) {
        const applyForPages = [
            'catalog-product-view'
        ];
        $(document).ready(() => {
            const checkExist = setInterval(() => {
                const shouldApply = applyForPages.filter((item) => {
                    return document.body.classList.toString().split(' ').indexOf(item) !== -1;
                }).length;
                if (window.sliders === undefined || !window.sliders.length || !shouldApply) {
                    return;
                }
                window.sliders.forEach((element) => {
                    element.swiper.on('observerUpdate', () => {
                        if (element.swiper.slideTo(0)) {
                            element.swiper.off('observerUpdate');
                        }
                    });
                });
                clearInterval(checkExist);
            }, 100);
        });
    });
