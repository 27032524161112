<template>
    <form class="quick-filter">
        <div class="title">
            <span v-text="title" />
        </div>
        <div class="content">
            <quick-filter-select
            v-model="selectedBrand"
            :label="brandSelectLabel"
            inputName="brand"
            wrapperId="brand_select_container"
            :options="brandOptions"
            :change="selectBrand" />
            <quick-filter-select
            v-model="selectedModel"
            :label="modelSelectLabel"
            inputName="sc_model"
            wrapperId="type_select_container"
            :options="modelOptions"
            :disabled="!modelOptions"
            :change="selectModel"/>
            <quick-filter-select
            v-model="selectedType"
            :label="typeSelectLabel"
            inputName="filterclass"
            wrapperId="filter_select_container"
            :options="typeOptions"
            :disabled="!typeOptions"
            :change="selectType"/>
            <div>
                <a :href="url" v-text="buttonLabel" />
            </div>
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import Select from './components/Select.vue'

export default {
    components: {
        'quick-filter-select': Select
    },
    data() {
        return {
            brandOptions: false,
            modelOptions: false,
            typeOptions: false,
            selectedBrand: '',
            selectedModel: '',
            selectedType: '',
            url: ''
        }
    },
    methods: {
        selectBrand() {
            if (this.selectedBrand) {
                this.updateQueryStringParameter('brand', this.selectedBrand)
                this.modelOptions = this.getRemainingOptions([
                    {
                        code: 'brand',
                        value: this.selectedBrand
                    }
                ])
            }
        },
        selectModel() {
            if (this.selectedBrand) {
                this.updateQueryStringParameter('model', this.selectedModel)
                this.typeOptions = this.getRemainingOptions([
                    {
                        code: 'brand',
                        value: this.selectedBrand
                    },
                    {
                        code: 'model',
                        value: this.selectedModel
                    }
                ])
            }
        },
        selectType() {
            this.updateQueryStringParameter('type', this.selectedType)
        },
        /**
        * Add or update query string parameters with regular expression
        */
        updateQueryStringParameter(attributeCode, attributeValue) {
            let regex = new RegExp("([?&])" + attributeCode + "=.*?(&|$)", "i")
            let separator = this.url.indexOf('?') !== -1 ? "&" : "?"

            if (this.url.match(regex)) {
                this.url = this.url.replace(regex, '$1' + attributeCode + "=" + attributeValue + '$2')
            } else {
                this.url = this.url + separator + attributeCode + "=" + attributeValue
            }
        },
        getRemainingOptions(attributes = null) {
            let queryParams = ''

            if (attributes) {
                attributes.forEach((attribute, index) => {
                    if (index === 0) {
                        queryParams = queryParams + '?'
                    }
                    queryParams = queryParams + attribute.code + '=' + attribute.value
                    if (index + 1 !== attributes.length) {
                        queryParams = queryParams + '&'
                    }
                })

                axios.get('/quickfilter/quickfilter/index' + queryParams)
                    .then(response => {
                        if (attributes.length === 1) {
                            this.modelOptions = response.data
                        } else if (attributes.length === 2) {
                            this.typeOptions = response.data
                        }
                    })
            } else {
                axios.get('/quickfilter/quickfilter/index')
                    .then(response => {
                        if (!attributes) {
                            this.brandOptions = response.data
                        }
                    })
            }
        }
    },
    computed: {
        title() {
            return window.quickFilterData.quickFilterWidgetTitle
        },
        allProductsPageUrl() {
            return window.quickFilterData.allProductsPageUrl
        },
        brandSelectLabel() {
            return window.quickFilterData.brandSelectLabel
        },
        modelSelectLabel() {
            return window.quickFilterData.modelSelectLabel
        },
        typeSelectLabel() {
            return window.quickFilterData.typeSelectLabel
        },
        buttonLabel() {
            return window.quickFilterData.buttonLabel
        }
    },
    created() {
        this.getRemainingOptions()
        this.url = this.allProductsPageUrl
    }
}
</script>
