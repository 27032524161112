<template>
    <div v-if="showPopup" class="vue-popup choice-check-popup" id="choice-check">
        <div class="modal-backdrop"></div>
        <div class="modal" v-on-clickaway="cancel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <strong class="modal-title" v-html="choiceCheckData.choiceCheckTitle" />
                        <div class="close">
                            <i @click="cancel()" class="fas fa-times"></i>
                        </div>
                    </div>
                    <div class="modal-body">
                        <p class="notification" v-html="choiceCheckData.choiceCheckWarning" />
                        <p class="content" v-html="choiceCheckData.choiceCheckContent" />
                    </div>
                    <div class="modal-footer">
                        <button @click="cancel()" :title="choiceCheckData.choiceCheckCancelText" class="action secondary">
                            <span v-text="choiceCheckData.choiceCheckCancelText" />
                        </button>
                        <button @click="addToCart()" :title="choiceCheckData.choiceCheckContinueText" class="action primary">
                            <span v-text="choiceCheckData.choiceCheckContinueText" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from 'vue-clickaway';

    export default {
        mixins: [ clickaway ],
        data() {
            return {
                choiceCheckData: window.choiceCheckData,
                showPopup: false,
                resolve: '',
                reject: ''
            }
        },
        methods: {
            addToCart() {
                this.resolve()
            },
            cancel() {
                this.reject()
            },
            openPopup() {
                let self = this
                this.showPopup = true

                const promise = new Promise((resolve, reject) => {
                    self.resolve = resolve
                    self.reject = reject
                }).finally(function() {
                    self.showPopup = false
                })

                return promise
            }
        },
        created() {
            window.openPopup = this.openPopup
        }
    }
</script>
